// import BapujiPng from "../../../Utils/images/Testimonial/Bapuji_Mallik.png";
import MonalishaPng from "../../../Utils/images/Testimonial/testimonial-1.jpg";
import PriyankaPng from "../../../Utils/images/Testimonial/testimonial-2.jpg";

const data = [
{
src: MonalishaPng,
name: "Jaspreet Parida",
description:
"ICONIC dance studio is an absolute gem! Balaraju Sir's exceptional choreography and vibrant teaching style make every class a joyful experience. The added emphasis on fitness is a fantastic bonus. The studio's supportive community has not only enriched my dance journey but also led to lifelong friendships. I'm grateful for the holistic and unforgettable experience that ICONIC provides",
},
// {
// src: BapujiPng,
// name: "Bapuji Mallik",
// description:
// "If you're new to exercise, you aren't sure what types of exercise you like, or you feel intimidated when walking into a new workout environment, online training is an excellent reprieve from the traditional gym or studio.According to me dance is the best fitness and workout where you never get bored,dance is the fun way to express yourself so yes this platform will give you such experience. Every session is mind blowing. Quality is awesome. Trainers are best.It is easy to learn for from  beginners to advanced. Motivation by the trainers and their coaching can't be described by words.Trainers are really comes with utmost positivity throughout the session.its been a good journey with happyprancers.\nThank you happyprancer",
// },
{
src: PriyankaPng,
name: "Sai Raghav",
description:
"I can't express how much I adore ICONIC dance studio! Balaraju Sir's choreography and dance steps are just incredible. I enthusiastically rate it 5 stars because every class is an exciting adventure. Dancing here not only makes me happy but also helps me improve. I'm hooked and couldn't ask for a better place to dance my heart out!",
},
];

export default data;

