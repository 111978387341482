import React from "react";
import NavBar from "../Components/NavBar";
import "./about_us.css";
import Footer from "../Components/Home/Footer";

const AboutUs = () => {
return (
<>
<div className="Background2 flex flex-col items-center ">
<NavBar />
<div className="text-white flex flex-col items-center w-100 mt-[2rem] p-0 overflow-x-hidden w-[90vw] max-w-[80%]">
<h1 className="sans-sarif text-[4rem] text-center mt-9 font-Dosis">About Us</h1>
<h3 className="sans-sarif text-[2rem] text-center ">KNOW WHAT WE ARE</h3>
<p className="mt-8">
  ICONIC is a prominent dance and fitness studio nestled in the heart of Berhampur, Odisha. Our belief in the transformative power of dance drives us. We see dance as a way to express oneself, breaking boundaries and embracing diversity. Our studio is a welcoming space for people of all backgrounds and ages to discover the joy of movement, explore their inner artist, and connect within a lively community.
</p>
<p className="mt-5">
   Founded in 2014 by the visionary Narthu Balaraju, a respected master instructor skilled in various dance forms, Iconic has become a central figure in the local dance scene. Balaraju's expertise in hip-hop guides our studio. What started as a passionate endeavor has grown into a nurturing sanctuary where creativity thrives and dreams take shape. 
</p>
<p className="mt-5">
 Our journey is marked by impressive achievements, including captivating performances on popular reality shows like Dance+, Dance Deewane, and DOD. The Iconic Crew consistently wows audiences with their exceptional talents. 
</p>
<p className="mt-6 mb-20">
Whether you're a seasoned dancer or taking your first steps on the dance floor, our studio offers a supportive and encouraging atmosphere. Our classes cater to a range of skill levels, ensuring that everyone can find their rhythm and build their dance journey at their own pace. Come be a part of our dynamic community at Iconic, where we celebrate the beauty of movement and the boundless possibilities of self-expression.
</p>
{/* <div className="my-8 text-[1.2rem] flex flex-col gap-6 w-[90vw] max-w-[60rem] border-[0]">
<h1>WATCH MORE ON OUR YOUTUBE</h1>
<div>
<iframe
// width="844"
// height="515"
src="https://www.youtube.com/embed/pPzIbKB2GNQ"
className="w-[80vw] h-[45vw]"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
></iframe>
</div>
</div> */}
</div>
</div>
<Footer />
</>
);
};

export default AboutUs;

