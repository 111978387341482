import logo from "../../Utils/Happy/images/logo1.jpg";
import facebook from "../../Utils/Assests/FB.svg";
import instagram from "../../Utils/Assests/INSTA.svg";
import youtube from "../../Utils/Assests/youtube.svg";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";

const Footer = (props) => {
// const Navigate = useNavigate();

useEffect(() => {
window.scrollTo(0, 0);
}, [props.initialContent]);


return (
<div>
<div className="w-full h-[.2rem] bg-[#3c3257]"></div>
<div className="bg-[#000] ">
<div className="flex flex-wrap justify-between max600:flex-col max600:justify-center p-12 gap-6 max1358:justify-center ">
<div className="mb-5">
<a href="/" className="transition duration-200 flex justify-center ">
<img className="w-[15rem] rounded" src={logo} alt="" />                                     {/* Logo  */}
</a>
</div>

<ul className=" flex gap-32 max950:gap-16 text-[#fff] flex-wrap max1050:justify-center">           {/* Quick Links  */}
<li className="RussoOne flex flex-col gap-4 items-center text-center ">
<h2 className="">Affiliated Gyms</h2>
<hr className="w-[100%] text-[#fff] " />

<a
className="cursor-pointer text-[#fff] text-decoration-none"
href="https://happyprancer.com/"
target={"_blank"}
rel="noreferrer"
>
HappyPrancer
</a>
<a
className="cursor-pointer text-[#fff] text-decoration-none"
href="https://bworkzlive.com/"
target={"_blank"}
rel="noreferrer"
>
BWORKZ
</a>
<a
className="cursor-pointer text-[#fff] text-decoration-none"
href="https://awsaiapp.com/"
target={"_blank"}
rel="noreferrer"
>
AWSAIAPP
</a>

</li>


</ul>
</div>

<div className=" h-16 bg-[#3c3257] flex w-full items-center justify-start px-[2rem]">                                                                            {/* Social media Links  */}
<div className="flex  bg-white justify-between  items-center w-[13rem]  rounded-2xl h-12 p-4">
<a
href="https://www.instagram.com/iconidancestudio/?igshid=NGExMmI2YTkyZg%3D%3D"
target={"_blank"}
rel="noreferrer"
>
<img
src={instagram}
alt=""
className="hover:mr-2 hover:w-8 hover:h-8 w-6 h-6"
/>
</a>
<a
href="https://www.facebook.com/ayesha.patro.7?paipv=0&eav=AfYg3-5CC4VPzSDuDhj-xna4xDAM6s6942u7u_gDmawREQxq9dyrT5zEUT5rjdHBjAM&_rdr"
target={"_blank"}
rel="noreferrer"
>
<img
src={facebook}
alt=""
className="hover:mr-2 hover:w-8 hover:h-8 w-6 h-6"
/>
</a>

<a
href="https://www.youtube.com/@ayeshapatro1723"
target={"_blank"}
rel="noreferrer"
>
<img
src={youtube}
alt=""
className="bg-white hover:mr-2 hover:w-8 hover:h-8 w-6 h-6"
/>
</a>


</div>
</div>

<div className="p-4 flex justify-center text-white gap-2 font-poppins max536:flex-col max536:text-center RussoOne">        {/*      Policy Links      */}
<Link to={"/query"} className="cursor-pointer text-[#fff] text-decoration-none"> Contact Us

</Link>
{/* <div className="bg-[#3c3257] w-1 border-[fff] rounded-md"></div>
<Link to={"/terms"} className="cursor-pointer text-[#fff] text-decoration-none"> Terms and Condition </Link>
<div className="bg-[#3c3257] w-1 border-[fff] rounded-md"></div>
<Link to={"/refund"} className="cursor-pointer text-[#fff] text-decoration-none">  Cancellation/Refund Policy </Link> */}
<div className="bg-[#3c3257] w-1 border-[fff] rounded-md"></div>
<h5 className="text-[1rem]">  All Rights are Reserved By ICONIC DANCE STUDIO</h5>
</div>
</div>
</div>
);
};

export default Footer;

