import React from "react";
import { useState } from "react";
import emailJs from "@emailjs/browser";
import "../Components/comp/Query.css";
import Footer from "../Components/Home/Footer";
import NavBar from "../Components/NavBar";

import { useContext } from "react";
import Context from "../Context/Context";
import "./query.css";

export default function Query() {
  const UtilCtx = useContext(Context).util;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    UtilCtx.setLoader(true);

    const params = {
      name: name,
      user_email: email,
      address: address,
      message: message,
    };

    try {
      await emailJs.send(
        "service_x9gqsk8",
        "template_1rkvqay",
        params,
        "user_X7r11lSn4Rg1cG6RBM4kE"
      );

      UtilCtx.setLoader(false);
      alert("Your message is sent. We'll get back to you soon!");
      setName("");
      setEmail("");
      setAddress("");
      setMessage("");
    } catch (e) {
      alert(e.message);
      UtilCtx.setLoader(false);
    }
  };

  return (
    <>
      <NavBar />
      <div className="flex flex-col gap-16 py-10 items-center Background">
        <div className="flex flex-col items-center ">
          <h2 className="text-[3rem] font-Dosis max500:text-[1.6rem] text-white">
            Have Questions?
          </h2>
          <p className="text-white sans-sarif">
            we are always here for a good cup or coffee
          </p>
        </div>
        <div className="mb-10">
          <div className="bg-white flex  px-4 shadow-2xl gap-8 max500:w-[90vw] ">
            <div className="m-2 p-10 max850:hidden bg-[#3c3257] w-[20rem] h-[33rem] flex flex-col gap-[1rem]">
              <span className="font-bold sans-sarif text-[1.6rem] w-full text-red-100  leading-7">
                Contact Information
              </span>
              <div className="text-[0.9rem] sans-sarif text-red-100">
                Already a Customer or need help ?
              </div>
              <div className="flex text-white text-[0.8rem] flex-col">
                <span className="text-[#fff] font-bold text-[1.4rem] mt-[2rem]">
                  Contact Support
                </span>
                <span className="font-bold">ICONIC DANCE STUDIO</span>
                <span>Narthu Balaraju</span>
                <span>Email - narthubalaraju@gmail.com</span>
                <span>Mobile - 9778088862</span>
                <span>
                  Address -{" "}
                  <a
                    className="no-underline text-white"
                    href="https://maps.app.goo.gl/9Dy52oTgeEQvekAX8"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Subarao square, Utkal Ashram Rd, Brahmapur, Odisha 760001
                  </a>
                </span>{" "}
              </div>
            </div>
            <div className="border-[#9d9d9d78] border-[1px] bg-black my-8 max850:hidden"></div>
            <div className="sans-sarif font-bold flex flex-col items-center gap-10 py-4">
              <h3>FILL IT UP!</h3>
              <form className="flex flex-col gap-8 items-center">
                <ul className="w-[25rem] flex flex-col items-center gap-6 max500:w-[70vw]">
                  <li className="flex gap-4 items-center justify-between w-[23rem] max500:flex-col max500:gap-1 max500:items-start max500:w-[100%]">
                    <label>Name</label>
                    <input
                      className="bg-[#d9d9d980] rounded-md py-[0.4rem] px-2 max500:w-[100%]"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </li>
                  <li className="flex gap-4 items-center justify-between w-[23rem] max500:flex-col max500:gap-1 max500:items-start max500:w-[100%]">
                    <label>Email</label>
                    <input
                      className="bg-[#d9d9d980] rounded-md py-[0.4rem] px-2 max500:w-[100%]"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </li>
                  <li className="flex gap-4 items-center justify-between w-[23rem] max500:flex-col max500:gap-1 max500:items-start max500:w-[100%]">
                    <label>Address</label>
                    <input
                      className="bg-[#d9d9d980] rounded-md py-[0.4rem] px-2 max500:w-[100%]"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </li>
                  <li className="flex gap-4 items-center justify-between w-[23rem] max500:flex-col max500:gap-1 max500:items-start max500:w-[100%]">
                    <label>Message</label>
                    <textarea
                      className="bg-[#d9d9d980] rounded-md py-[0.4rem] px-2 h-[8rem] max500:w-[100%]"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </li>
                </ul>
                <button
                  className="sans-sarif bg-[#3c3257] rounded-lg w-[8rem] text-white  py-2 "
                  onClick={onSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
