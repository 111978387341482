const data = {
rows: [
{
title: "How far is ICONIC dance studio from my home?",
content: `ICONIC dance studio is conveniently located in the heart of Berhampur, you can find us (HERE)`,
},
{
title: "What are the pricing details in short?",
content: `ICONIC offers competitive pricing with a one-time registration fee and affordable monthly fees for both kids and adults. Reach out to us for detailed pricing information.`,
},
{
title: "Can dancing at ICONIC help reduce calories?",
content: `Yes, dancing is a fantastic way to burn calories while enjoying yourself. Our energetic dance sessions contribute to a healthier lifestyle.`,
},
{
title: "What classes does ICONIC offer?",
content: `ICONIC offers a diverse range of dance styles including Bollywood, hip-hop, house, breaking, and more. Explore various genres and find your groove with us.`,
},
// {
// title: "5. I am a GYM owner. How can I connect?",
// content: `Connect with us at awsaiapp.com to explore how we can assist you with detailed instructions and support.`,
// },
],
};

export default data;
