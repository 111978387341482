// import React, { useContext } from "react";
// import { useNavigate } from "react-router";
// // import "../comp/Subscription.css";
// import Context from "../Context/Context";
// import HappyprancerPaypalMonthly from "../Components/Subscription/HappyprancerPaypalMonthly";
// import HappyprancerRazorpayMonthly from "../Components/Subscription/HappyprancerRazorpayMonthly";
// import NavBar from "../Components/NavBar";
// import Footer from "../Components/Home/Footer";
// import HappyprancerPaypalHybrid from "../Components/Subscription/HappyprancerPaypalHybrid";

// export default function Subscription() {
// const text = {
// Heading: 'Monthly Membership Subscription',
// sub_Heading: 'see what are the pricing in details'
// };
// const Ctx = useContext(Context);
// const UserCtx = useContext(Context).userData;

// const Navigate = useNavigate();


// const paymentHandler = (item) => {
// if (item.currency === "INR" && item.subscriptionType === "Monthly") {
// return <HappyprancerRazorpayMonthly />;
// }
// if (item.currency === "USD" && item.subscriptionType === "Monthly") {
// return <HappyprancerPaypalMonthly />;
// }
// if (item.currency === "USD" && item.subscriptionType === "Hybrid") {
// return <HappyprancerPaypalHybrid />;
// }
// };

// return (
// <>
// <NavBar />
// <div className="Back text-[1.5rem]  flex  flex-col items-center h-auto min-h-screen max980:h-[auto] justify-center gap-[5rem] pb-20 bg-[#f5f5f5]">
// <div className="text-center mt-[7rem] sans-sarif ">
// <h1>{text.Heading}</h1>
// <h3 className="text-[1rem]">{text.sub_Heading}</h3>
// </div>
// <ul className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 ">
// {Ctx.productList.map((item) => {
// return (
// <li className="bg-white w-[24rem] py-16 px-8 rounded-[2rem] z-0  flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-[#225c59] border-[0.1rem]">
// <p className="text-[1.6rem]">{item.heading}</p>
// <ul className=" text-[1rem] h-auto pl-0 flex flex-col">
// {item.provides.map((i) => {
// return (
// <li >
// <p>{i}</p>
// </li>
// );
// })}
// </ul>
// <h1 className="text-left w-[100%]">
// {(item.currency === "INR" ? "₹ " : "$ ") +
// parseInt(item.amount) / 100 +
// "/" +
// item.durationText}
// </h1>
// {Ctx.isAuth ? (
// <div className=" ">
// {UserCtx.status === "Active" ? (
// <p className="text-[1rem] w-[15rem] px-12 py-2 rounded-2xl text-[#225c59] bg-white border-[#225c59] border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]">
// Already Subscribed
// </p>
// ) : (
// <>{paymentHandler(item)} </>
// )}
// </div>
// ) : (
// <button
// onClick={() => {
// Navigate("/signup");
// }}
// className="w-[15rem] bg-[#225c59] text-white px-12 py-2 rounded-2xl hover:text-[#225c59] hover:bg-white hover:border-[#225c59] hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]"
// >
// Sign Up
// </button>
// )}
// </li>
// );
// })}
// </ul>
// </div>
// <Footer />
// </>
// );
// }

import React from "react";
import { useNavigate } from "react-router";
import "../Components/comp/Subscription.css";
// import Context from "../Context/Context";
import Navbar from "../Components/NavBar"

export default function Subscription() {
  // const Ctx = useContext(Context);

  const Navigate = useNavigate();

  return (
    <>
      <Navbar/>
      <section className="Back  text-[1.5rem] py-[5rem] flex  flex-col items-center h-[100rem] max1414:h-[auto] justify-center gap-[5rem]  ">
        <div className="text-center text-[white] text-[3.4rem] max500:text-[2rem] mt-20 font-bebas-neue ">
          <h1 className="font-Dosis font-bold text-[3.5rem]">
            CHECK OUT OUR EXECLUSIVE OFFER!
          </h1>{" "}
          {/*    Top header     */}
          <h3 className="text-[1.5rem] max500:text-[1rem] font-Dosis">
            see what are the pricing in details
          </h3>
        </div>
        <div className="flex flex-wrap justify-center items-center w-full max-w-[80rem]  gap-28 max800:flex-col">
          {/*  Subscription  Plans     */}

          {/*  Dance Delights    */}
          <div className="flex flex-wrap w-full gap-[10rem] justify-center items-center">
            <div className="bg-white w-[22rem]   h-[35rem]  rounded-[2rem]  flex flex-col justify-center items-center gap-0 shadowSubscribe   max450:w-[90vw] max450:gap-[1.2rem] max450:text-[1rem]   max450:p-12  border-[0.1rem]">
              <p className="font-gidugu bg-gradient-radial flex items-center justify-center leading-[1.5rem] h-[107px] w-[303px] max500:text-[1.9rem] text-[2rem] font-Dosis mb-[1.2rem]">
                DANCE DELIGHTS{" "}
              </p>
              <div className=" flex w-[17rem] flex-col gap-5 items-center text-left font-Dosis">
                <ul className="list-disc text-[0.8rem] ">
                  <li>
                    <p className="font-poppins    ">
                      Class meets 5 days a week{" "}
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins   ">
                      Air-conditioned studio for comfort
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Stretching sessions included
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Suitable for all levels of dancers
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Variety of dance styles: jazz,
                      <br /> hip-hop, and contemporary
                    </p>
                  </li>
                </ul>
              </div>
              <h1 className="text-center text-[0.9rem] font-russo w-[100%] ">
                (One time joining/ registration fee -{" "}
                <span className="font-bold">₹1000</span> )
              </h1>
              <h1 className="text-center text-[1.4rem] font-russo w-[100%] font-poppins">
                {" "}
                ₹600<span className="text-[0.81rem] font-bold">
                  /monthly
                </span>{" "}
                <span className="text-[2rem] font-bold">/</span> ₹1500
                <span className="text-[0.81rem] font-bold">/Quarterly</span>{" "}
              </h1>

              <button
                onClick={() => {
                  Navigate("/query");
                }}
                className="w-[15rem] leading-0 textbox px-12 py-2 font-russo rounded-[11px] mt-[2rem] hover:text-[#ffff] hover:bg-[#3C3257] border-[#3C3257] border-[1px] text-[#3C3257] h-[3rem] flex justify-center items-center  max450:w-[40vw]"
              >
                Select
              </button>
            </div>
            {/*  BWORKZ FITNESS -    */}

            <div className="bg-white w-[22rem]   h-[35rem]  rounded-[2rem]  flex flex-col justify-center items-center gap-0 shadowSubscribe   max450:w-[90vw] max450:gap-[1.2rem] max450:text-[1rem]   max450:p-12  border-[0.1rem]">
              <p className="font-gidugu bg-gradient-radial flex items-center justify-center leading-[1.5rem] h-[107px] w-[303px] max500:text-[1.9rem] text-[2rem] font-Dosis mb-[1.2rem]">
                BWORKZ FITNESS{" "}
              </p>
              <div className=" flex w-[17rem] flex-col gap-5 items-center text-left font-Dosis">
                <ul className="list-disc text-[0.8rem] ">
                  <li>
                    <p className="font-poppins    ">
                      Class meets 5 days a week
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins   ">
                      Air-conditioned studio for comfort
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Stretching sessions included
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Focus on Bollywood dance fitness
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Fun and energetic dance routines
                    </p>
                  </li>
                </ul>
              </div>
              <h1 className="text-center text-[0.9rem] font-russo w-[100%] ">
                (One time joining/ registration fee -{" "}
                <span className="font-bold">₹1000</span> )
              </h1>
              <h1 className="text-center text-[1.4rem] font-russo w-[100%] font-poppins">
                {" "}
                ₹800<span className="text-[0.81rem] font-bold">
                  /monthly
                </span>{" "}
                <span className="text-[2rem] font-bold">/</span> ₹2100
                <span className="text-[0.81rem] font-bold">/Quarterly</span>{" "}
              </h1>

              <button
                onClick={() => {
                  Navigate("/query");
                }}
                className="w-[15rem] leading-0 textbox px-12 py-2 font-russo rounded-[11px] mt-[2rem] hover:text-[#ffff] hover:bg-[#3C3257] border-[#3C3257] border-[1px] text-[#3C3257] h-[3rem] flex justify-center items-center  max450:w-[40vw]"
              >
                Select
              </button>
            </div>
          </div>
          <div className="flex flex-wrap w-full gap-[10rem] justify-center items-center">
            {/*  YOGA & MEDITATION -    */}

            <div className="bg-white w-[24rem] py-[3rem]  h-[39rem]  rounded-[2rem]  flex flex-col justify-center items-center gap-0 shadowSubscribe   max450:w-[90vw] max450:gap-[1.2rem] max450:text-[1rem]   max450:p-12  border-[0.1rem]">
              <p className="font-gidugu bg-gradient-radial flex items-center justify-center leading-[1.5rem] h-[107px] w-[303px] max500:text-[1.9rem] text-[2rem] font-Dosis mb-[1.2rem]">
                YOGA & MEDITATION{" "}
              </p>
              <div className=" flex w-[17rem] flex-col gap-5 items-center text-left font-Dosis">
                <ul className="list-disc text-[0.8rem] ">
                  <li>
                    <p className="font-poppins    ">
                      Focus on improving flexibility, strength, and relaxation{" "}
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins   ">
                      Physical yoga practice with mindfulness meditation
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Modifications offered for all levels
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Emphasis on balance, breathwork and mindfulness
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Suitable for all levels of experience
                    </p>
                  </li>
                </ul>
              </div>
              <h1 className="text-center text-[0.9rem] font-russo w-[100%] ">
                (One time joining/ registration fee -{" "}
                <span className="font-bold">₹1000</span> )
              </h1>
              <h1 className="text-center text-[1.4rem] font-russo w-[100%] font-poppins mt-[2rem]">
                {" "}
                ₹600<span className="text-[0.81rem] font-bold">
                  /monthly
                </span>{" "}
                <span className="text-[2rem] font-bold">/</span> ₹1500
                <span className="text-[0.81rem] font-bold">/Quarterly</span>{" "}
              </h1>

              <button
                onClick={() => {
                  Navigate("/query");
                }}
                className="w-[15rem] leading-0 textbox px-12 py-2 font-russo rounded-[11px] mt-[2rem] hover:text-[#ffff] hover:bg-[#3C3257] border-[#3C3257] border-[1px] text-[#3C3257] h-[3rem] flex justify-center items-center  max450:w-[40vw]"
              >
                Select
              </button>
            </div>
            {/*  WEDDING CHOREOGRAPHY -    */}

            <div className="bg-white w-[24rem] py-[3rem]  h-[39rem]  rounded-[2rem]  flex flex-col justify-center items-center gap-0 shadowSubscribe   max450:w-[90vw] max450:gap-[1.2rem] max450:text-[1rem]   max450:p-12  border-[0.1rem]">
              <div className="flex justify-center items-center w-[2rem]">
                <p className="font-gidugu bg-gradient-radial flex items-center justify-center leading-[2.5rem] h-[107px] w-[303px] max500:text-[1.9rem] text-[2rem] font-Dosis mb-[1.2rem]">
                  WEDDING CHOREOGRAPHY{" "}
                </p>
              </div>
              <div className=" flex w-[17rem] flex-col gap-5 items-center text-left font-Dosis">
                <ul className="list-disc text-[0.8rem] ">
                  <li>
                    <p className="font-poppins    ">
                      Personalized choreography services for your special day{" "}
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins   ">
                      Focus on creating a unique and memorable performance
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Choice of music and dance style
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Flexible scheduling to accommodate your wedding plans
                    </p>
                  </li>
                  <li>
                    <p className="font-poppins ">
                      Emphasis on fun, confidence, and enjoyment
                    </p>
                  </li>
                </ul>
              </div>
              <div className="flex relative h-[20%] w-[80%] max450:w-[124%] justify-center gap-[5rem] items-center">
                <div className="flex flex-col justify-center items-center  text-[0.8rem] font-russo w-[100%]  font-poppins ">
                  <p className="leading-6 font-bold">
                    SOLO:- <br />{" "}
                    <span className="font-light">₹2000 to ₹2500</span>{" "}
                  </p>
                  <p className="leading-6 font-bold">
                    DUET:- <br />{" "}
                    <span className="font-light">₹3000 to ₹3500</span>{" "}
                  </p>
                </div>
                <span className="absolute text-[8rem] font-light z-10">/</span>
                <p className="font-poppins w-[80%] text-[0.8rem]">
                  (Group depends upon to members)
                </p>
              </div>

              <button
                onClick={() => {
                  Navigate("/query");
                }}
                className="w-[15rem] leading-0 textbox px-12 py-2 font-russo rounded-[11px] mt-[2rem] hover:text-[#ffff] hover:bg-[#3C3257] border-[#3C3257] border-[1px] text-[#3C3257] h-[3rem] flex justify-center items-center  max450:w-[40vw]"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}