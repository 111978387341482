
const Choreography = () => {
return (
<div className="w-[100%] flex flex-col justify-center items-center pt-6">
<iframe
src="https://www.youtube.com/embed/LL1rcLytUXE"
title="@#^&#{src-Components-Choreography-title}#$^#@"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowfullscreen
className="w-[76vw] h-[42vw] max1050:w-[86vw] max1050:h-[47vw]"
></iframe>
</div>
);
};

export default Choreography;

