import React from "react";
import video from "../../Utils/images/video.mp4";

const Header = () => {

return (
<div className=" flex items-center justify-center h-[36rem] pb-20">
<div className="absolute z-10 flex flex-col items-center w-screen content ">
<div className="w-[auto] text-left flex">
<h1 className="w-full max1250:w-[50%] max536:w-[90vw] max800:w-[80%]  text-[5.7rem] max800:text-[3.8rem] max1250:text-[4.5rem] text-[#fff] ">
 
</h1>
</div>
</div>
<div className="-z-10 ">
<video
autoPlay
loop
muted
playsInline={true}
controls={false}
className="object-cover object-top h-[36rem] w-screen   max-w-screen"
>
<source src={video} type="video/mp4" />
</video>

<h5  className="m-0  w-full flex items-center justify-center  bg-black  h-[5rem] shrink-0 text-[1rem] font-format">
<p className="Fussion1 text-center  text-[1.5rem] max375:text-[1rem] text-white">
            Ignite Your Dancing Flame 
</p>
</h5>

</div>
</div>
);
};

export default Header;

