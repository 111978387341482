import React, { useState, useContext } from "react";
import Context from "../../Context/Context";
import Pagination from "@mui/material/Pagination";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";

// Other necessary imports and components

const PreviousSessionsMobile = () => {
const unpaidUser = {
text: 'You need a subscription to access the Previous classes.',
}
const Navigate = useNavigate();
const Ctx = useContext(Context);
const UtilCtx = useContext(Context).util;

const [classId, setClassId] = useState("");
const [recordingLink, setRecordingLink] = useState("");
const [showUpdateContainer, setShowUpdateContainer] = useState(false);

const itemsPerPage = 4; // Set the desired number of items per page
const [currentPage, setCurrentPage] = useState(1);
const totalPages = Math.ceil(Ctx.previousClasses.length / itemsPerPage);
const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
// eslint-disable-next-line
const [editingIndex, setEditingIndex] = useState(-1);

const instructorNamesArray = ['Narthu Balaraju'];

const formatDate = (epochDate) => {
const date = new Date(epochDate);
const day = String(date.getDate()).padStart(2, "0");
const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1 to get the correct month
const year = date.getFullYear();
return `${day}/${month}/${year}`;
};

const handleAddLink = (classId) => {
setShowUpdateContainer(true);
setClassId(classId);
setRecordingLink("");
};

const handleCancel = () => {
setShowUpdateContainer(false);
setClassId("");
setRecordingLink("");
};

const onRecordingUpdate = async (e) => {
e.preventDefault();
UtilCtx.setLoader(true);

try {
if (classId.length === 0 || recordingLink.length === 0) {
alert("Invalid Details");
UtilCtx.setLoader(false);
} else {
// Your API.put call to update the recording link here
// ...
await API.put("user", "/admin/edit-schedule-recording/iconic", {
body: {
classId: classId,
recordingLink: recordingLink,
},
});
alert("Updated");

// After successful update, update the local state
setClassId("");
const updatedClasses = Ctx.previousClasses.map((clas) => {
if (clas.classId === classId) {
return {
...clas,
recordingLink: recordingLink,
};
}
return clas;
});
Ctx.setPreviousClasses(updatedClasses);
setShowUpdateContainer(false);
UtilCtx.setLoader(false);
}
} catch (e) {
alert(e.message);
UtilCtx.setLoader(false);
}
};
const sortedPreviousClasses = Ctx.previousClasses.sort(
(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
);

// eslint-disable-next-line
const onClassUpdated = async (classId, editedInstructorNames, editedClassType) => {
UtilCtx.setLoader(true);

try {
if (!editedInstructorNames) {
alert("Please select an instructor.");
UtilCtx.setLoader(false);
return;
}

if (!editedClassType) {
alert("Please select an Class Type.");
UtilCtx.setLoader(false);
return;
}

const updatedClasses = Ctx.upcomingClasses.map((c) =>
c.classId === classId ? { ...c, instructorNames: editedInstructorNames, classType: editedClassType } : c
);
await API.put("user", "/admin/edit-schedule-name/iconic", {
body: {
classId: classId,
instructorNames: editedInstructorNames,
classType: editedClassType
},
});
// alert("Updated");

// const tempData = [];
// Ctx.upcomingClasses.forEach((clas, i) => {
//   if (clas.classId === classId) {
//     clas.instructorNames = instructorName;
//   }
//   tempData.push(clas);
// });

Ctx.setUpcomingClasses(updatedClasses);

setEditingIndex(-1);
// setInstructorName("");
// setClassId("");
// setIsEditing(false);

UtilCtx.setLoader(false);

} catch (e) {
alert(e.message);
UtilCtx.setLoader(false);
}
};

const onInstructorNameChange = async (newInstructorName, classType, classId) => {
UtilCtx.setLoader(true);

try {
await API.put("user", "/admin/edit-schedule-name/iconic", {
body: {
classId: classId,
instructorNames: newInstructorName,
classType: classType
},
});

const updatedClasses = Ctx.previousClasses.map((clas) => {
if (clas.classId === classId) {
return {
...clas,
instructorNames: newInstructorName,
};
}
return clas;
});

Ctx.setPreviousClasses(updatedClasses);

UtilCtx.setLoader(false);
} catch (e) {
alert(e.message);
UtilCtx.setLoader(false);
}
};

return (
<>
{Ctx.userData.status === "InActive" && Ctx.userData.userType === "member" ? (
<div className="locked-screen text-center mt-[3.5rem]">
<button
className="fas fa-lock fa-4x text-[#800080] mb-4"
onClick={() => {
// Redirect the user to the subscription page
Navigate("/subscription");
}}
>
</button>
<h2>{unpaidUser.text}</h2>
<button
className="subscribe-button bg-[#800080] w-[8rem] h-[2.5rem] m-[2rem] rounded-[0.4rem] text-[white]"
onClick={() => {
// Redirect the user to the subscription page
Navigate("/subscription");
}}
>
Subscribe Now
</button>
</div>
) : <div>
<div className="w-full px-2 pb-4">
<h2 className="text-[1.4rem] mb-5 font-bold text-black-700 mt-10 text-center">Previous Sessions</h2>
<div className="grid gap-[1.4rem] md:gap-4 grid-cols-1 sm:grid-cols-2">
{sortedPreviousClasses
.slice(startIndex, endIndex)
.map((clas, i) => (
<div key={clas.classId} className="class-container">
<div
className="bg-gradient-to-r from-#1b7571  to-#1b7571 rounded-lg p-3 md:p-4 shadow-md"
style={{
background: `linear-gradient(to bottom,#f5c4f5, #f5c4f5)`,
boxShadow: "0 0px 15px rgba(0, 0, 0, 0.4)",
borderRadius: "1.8rem",
}}
>
<div className="flex justify-between items-center ">
<div className="w-[7rem] attractive-dropdown-container">
{Ctx.userData.userType === "admin" || Ctx.userData.userType === "instructor" ? (
<div className="dropdown-wrapper">

<select
className="rounded-[0.51rem] px-1 bg-[#f5c4f5] attractive-dropdown" // Add the CSS class "attractive-dropdown"
value={clas.instructorNames}
onChange={(e) => onInstructorNameChange(e.target.value, clas.classType, clas.classId)}

>
{instructorNamesArray.map((name) => (
<option key={name} value={name}>
{name}
</option>
))}
</select>
<div className="dropdown-arrow"></div>
</div>
) : (
<p className="rounded-[0.51rem] bg-[#f5c4f5]">{clas.instructorNames}</p>)}</div>
{showUpdateContainer && classId === clas.classId ? (
<button
className="sans-sarif text-[#e1e1e1] bg-[#2f2f2f] rounded-lg py-1 w-[4.8rem]"
onClick={handleCancel}
style={{
borderRadius: "0.8rem",
}}
>
Cancel
</button>
) : (
<button
className="sans-sarif bg-[#2f2f2f] text-[#fff] font-[400] rounded-lg py-1 w-[4.8rem]"
onClick={() => handleAddLink(clas.classId)}
style={{
borderRadius: "1.8rem",
}}
>
Add
</button>
)}
</div>
<div className="mb-1">Class: {clas.classType}</div>
<div>Date {formatDate(clas.date)}</div>
<div className="mb-2">Recording Link {clas.recordingLink ? <a href={clas.recordingLink} target="_blank" rel="noreferrer">Watch</a> : "No Link"}</div>

{showUpdateContainer && classId === clas.classId && (
<div className="mt-2">
<form className="update-container">
<input
placeholder="Recording Link"
className="bg-snow text-[#0008] sans-sarif px-4 py-1 rounded-lg w-[74%]"
value={recordingLink}
onChange={(e) => setRecordingLink(e.target.value)}
style={{
borderRadius: "1.8rem",
}}
/>
<button
className="sans-sarif bg-[#2f2f2f] text-[#e1e1e1] mt-2 ml-1 rounded-lg py-1 w-[4.8rem] "
onClick={onRecordingUpdate}
style={{
borderRadius: "1rem",
}}
>
Update
</button>
</form>
</div>
)}
</div>
</div>
))}
</div>
<div className="flex mb-[6rem] justify-center items-center mt-4 md:mt-6">
<Pagination
count={totalPages}
page={currentPage}
onChange={(event, value) => setCurrentPage(value)}
/>
</div>
</div>
</div>}
</>
);
};

export default PreviousSessionsMobile;
